<template>
  <VChart :data="chartData" type="line" :options="chartOptions" ref="chart"></VChart>
</template>

<script>
import VChart from '@/components/Charts/VChart'
import moment from 'moment-jalaali'

export default {
  name: 'LineChart',
  props: {
    value: {type: Array, default: () => []},
    title: {type: String, default: null},
    selectedId: {type: [Number], default: null}
  },
  components: {
    VChart
  },
  data() {
    const vm = this
    return {
      chartOptions: {
        onClick: function (_evt, activeElements) {
          const {index} = activeElements[0]
          const item = vm.value[index]
          vm.$emit('point:click', item)
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      }
    }
  },
  computed: {
    chartData() {
      const labels = []
      const data = []
      for (const item of this.value) {
        labels.push(moment.utc(item.created).format('MM/DD'))
        data.push(item.totalScore)
      }
      const selectedId = this.selectedId
      const items = this.value
      return {
        labels,
        datasets: [
          {
            active: true,
            label: this.title,
            backgroundColor: '#67B7DC',
            pointBackgroundColor: function (a) {
              if (!selectedId) {
                return '#67B7DC'
              }
              if (items[a.dataIndex].id === selectedId) {
                return 'red'
              } else {
                return '#67B7DC'
              }
            },
            pointStyle: 'circle',
            pointRadius: 5,
            pointHoverRadius: 15,
            data
          }
        ]
      }
    }
  }
}
</script>
